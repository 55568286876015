<template>
  <div class="">
      <br>
      <br>
    <div class="row justify-content-center align-content-end" >
      <div class="col-md-10">
      </div>
      <div class="col-md-7  bg-white rounded-lg shadow d-flex flex-column justify-content-end align-content-end" >
        <div class="scrollbarnya" style="height:300px;overflow:scroll;">
          <div class="shadow rounded-lg p-4 bg-white text-xs mb-3" v-for="(item, index) in comments" :key="index+'comments'">
            <table style="width:100%;">
              <tr>
                <td style="width:40px;">
                  <img :src="item.gambar" style="width:25px;height:25px;" class="rounded-circle " alt="">
                </td>
                <td>
                  <div class="ml-2">
                    <span class="cursor-pointer text-xs hover:text-red-600 hover:font-bold float-right" @click="hapus(item)">X</span>
                    <span class="text-xs capitalize font-semibold text-gray-600">{{item.username}}</span>
                    <div v-html="item.comment">
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
           <div id="scrollnya">  </div>
           </div>
        </div>
        <div style="width:90%;" class="row justify-content-center">
            <div class="col-7">
            <button type="button" @click="scrolldown" class="btn btn-sm btn-dark waves-effect float-right" ><span class="typcn typcn-arrow-down"></span></button>
            <hr>
        <form class=" align-bottom  align-content-end" action="" @submit.prevent="sendComment">
          <div class="sm-form">
          <textarea
          type="text"
          id="comment"
          name="comment"
          rows="2"
          placeholder="comment..."
          class="form-control md-textarea"
          v-model="vdata.comment"
          ></textarea>
          </div>
            <button type="submit"  class="btn btn-sm btn-dark "><span class="typcn typcn-pen"></span> Comment</button>
        </form>
            </div>
        </div>
    </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>

  </div>
</template>
<script>
import firebase from 'firebase'
let rdb = firebase.database();
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
import uniqid from 'uniqid'
export default{
    layout:'app_shop_admin',
  data(){
    return{
      vdata:{},
      comments:[],
      listid:[]
    }
  },
  watch:{
    comments(){
       setTimeout(() => {
          this.$el.querySelector('#scrollnya').scrollIntoView({ block: 'end',  behavior: 'smooth' })
        }, 2000);
    }
  },
  methods: {
      scrolldown(){
          this.$el.querySelector('#scrollnya').scrollIntoView({ block: 'end',  behavior: 'smooth' })
      },
    getComment(){
      sdb.collection("app_kasir_chat",false).doc().select(`select * from app_kasir_chat where room='room${this.$route.query.id_pengirim}' order by tanggal asc`).then(res => {
        this.comments=res;
        let username=this.$store.state.users.username?this.$store.state.users.username:this.$store.state.users.displayName
        this.listid=res.filter(e=>{
            if(e.username!=username){
                return e.id;
            }
        }).map(e=>e.id)
        console.log(this.listid)
        sdb.collection("app_kasir_chat",false).doc().select(`update app_kasir_chat set dibaca='true' where id in (${this.listid.toString()})`).then(res => {
            console.log(res);
        });
      });
    },
    format() {
      return this.$datefns.format(
        new Date(),
        "YYYY-MM-DD-HH-mm-ss"
      );
    },
    async sendComment(){
      let that=this
      this.vdata.id_pengirim=this.$store.state.users.id
      this.vdata.username=this.$store.state.users.username?this.$store.state.users.username:this.$store.state.users.displayName
      this.vdata.gambar=this.$store.state.users.gambar?this.$store.state.users.gambar:'https://upload.egov.balangankab.go.id/svg/group.svg'
      this.vdata.penerima='admin'
      this.vdata.tanggal=this.format();
      this.vdata.room=`room${this.$route.query.id_pengirim}`;
      this.vdata.id_penerima=this.$route.query.id_penerima
      this.vdata.dibaca='false'
      sdb.collection('app_kasir_chat').doc().set(this.vdata).then(res=>{
        rdb.ref(`/chat/${that.$route.query.id_pengirim}`).set({uniq:uniqid()})
        rdb.ref(`/chat/admin`).set({uniq:uniqid()})
      })
    },
    hapus(item){
      sdb.collection("app_kasir_chat").doc(item.id).delete().then(res => {
        rdb.ref(`/chat/${this.$route.query.id_pengirim}`).set({uniq:uniqid()})
        rdb.ref(`/chat/admin`).set({uniq:uniqid()})
      });
    },
  },
  mounted() {
    let that=this;
     sdb.collection().ceklogin(this.$store).then(res=>{
          // this.$router.push('/lembur')
        }).catch(err=>{
          // this.$router.push('/login')
        })
      rdb.ref('/chat/admin').on('value',(e)=>{ //read
          that.getComment();
      }) 
  },
}
</script>
<style scoped>
.scrollbarnya::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
.scrollbarnya::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 5px;
}
 
/* Handle */
.scrollbarnya::-webkit-scrollbar-thumb {
  cursor: grab;
  background: black; 
  border-radius: 5px;
}

/* Handle on hover */
.scrollbarnya::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
</style>